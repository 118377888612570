<template>
  <div class="viewContainer" >
    <v-container
      class="pa-4 pa-sm-8"
    >
      <v-card v-if="loading_main" class="content-tabs-wrapper pa-4 pa-sm-8 text-center" elevation="0">
        <v-progress-circular
          :size="32"
          color="primary"
          indeterminate
          class="ma-12"
        ></v-progress-circular>
      </v-card>
      <v-card v-if="!loading_main" class="content-tabs-wrapper px-4 py-6 px-sm-8 py-sm-10" elevation="0">
        <div class="content-container">
          <div class="d-flex justify-content-between align-center mb-8">
            <div class="text-h6">{{ $t('qr_code') }}</div>
            <v-switch
              v-model="switch_redemgo_account"
              color="primary"
              flat
              :label="$t('redemgo_account')"
              :hide-details="true"
              class="mt-0 pt-0"
              inset
              dense
              :ripple="false"
            ></v-switch>
          </div>

          <div v-if="switch_redemgo_account" class="text-subtitle-1 mb-4">{{ $t('msg_redemgo_account') }}</div>

          <v-alert
            v-if="!switch_redemgo_account && !bank_account.length"
            text
            type="info"
            icon="mdi-credit-card-plus-outline"
          >
            {{ $t('no_bank_account_head') }} <router-link class="inherit--text" :to="{ name: 'business.bank-account' }"><span class="font-weight-medium">{{ $t('manage_bank') }}</span></router-link>
          </v-alert>

          <v-alert
            v-if="!status_varify_business" 
            text
            type="error"
            icon="mdi-shield-alert-outline"
          >
            {{ $t('no_verify_business_head') }} <router-link class="inherit--text" :to="{ name: 'settings.business.verify-business' }"><span class="font-weight-medium">{{ $t('verify_business') }}</span></router-link>
          </v-alert>

          <div v-if="!switch_redemgo_account && bank_account.length">
            <x-select
              :items="bank_account"
              v-model="selectBankAccount"
              :label="$t('select_bank_account')"
              item-text="name"
              item-value="value"
              filled
            />
          </div>

          <div v-if="switch_redemgo_account || (!switch_redemgo_account && bank_account.length)">
            <x-text-field
              type="number"
              inputmode="numeric"
              :name="$t('purchase_amount')"
              v-model="QrCodeForm.amount"
              id="QrCodeForm.amount"
              ref="QrCodeForm.amount"
              class="title"
              rules="required"
              :label="$t('purchase_amount')"
              :placeholder="$t('purchase_amount')"
              :loading="inputLoading"
              filled
            />
            <v-btn
              @click="switch_redemgo_account ? generateQrCodeRedemgo() : generateQrCode()"
              depressed 
              block
              rounded
              x-large
              color="primary"
              class="no-caps mt-4"
              :loading="QrCodeForm.loading" :disabled="QrCodeForm.loading"
            >
              {{ $t('promptpay') }} <v-icon right>mdi-qrcode</v-icon>
            </v-btn>
          </div>
          <p class="mt-4 mb-0 text-subtitle-1">{{ $t('qr_bank_account_head') }}</p>
        </div>
      </v-card>
    </v-container>

    <v-dialog v-model="verifyCodeDialog" :fullscreen="$vuetify.breakpoint.xsOnly" persistent max-width="360" scrollable>
      <v-card>
        <v-card-title>{{ $t('redeem_points') }}</v-card-title>
        <v-card-text>
          <p class="text-body-1">
            <i18n path="redeem_points_verify">
              <template #points>
                {{ msg.points }}
              </template>
              <template #value>
                {{ msg.value }}
              </template>
              <template #customerNumber>
                {{ msg.customerNumber }}
              </template>
            </i18n>
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="" class="no-caps" rounded text @click="verifyCodeDialog = false">{{ $t('cancel') }}</v-btn>
          <v-btn color="primary" class="no-caps" rounded depressed :loading="QrCodeForm.loading" :disabled="QrCodeForm.loading" @click="verifyCodeDialog = false;processCode()">{{ $t('redeem_points') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>
<script>

import { mask } from 'vue-the-mask'
// import MenuBusiness from '@/components/ui/MenuBusiness.vue'
export default {
  components: {
    //MenuBusiness
  },
  data: () => ({
    locale: 'th',
    verifyCodeDialog: false,
    tab: 'tab-0',
    tabImg: 'about:blank',
    tabImgHeight: null,
    tabImgDefaultHeight: 780,
    tabImgAspectRation: 1.7778,
    tabImgs: [],
    inputLoading: false,
    switch_redemgo_account: false,
    QrCodeForm: {
      success: false,
      loading: false,
      amount: 0
    },
    msg: {
      points: 0,
      value: 0,
      customerNumber: ''
    },
    status_varify_business: null,
    bank_account: [],
    selectBankAccount: null,
    loading_main: true,
  }),
  directives: {
    mask
  },
  created () {
    // Set locale
    this.locale = 'th'
    this.moment.locale(this.locale.substr(0,2))
    let uuid = this.$auth.user().business_uuid ? this.$auth.user().business_uuid : this.$auth.user().uuid
    var channel = this.$pusher.subscribe(uuid);
    console.log(channel)
    channel.bind('webhook_qr_redemgo', (res_pusher) => {
      console.log(res_pusher)
      if(res_pusher && res_pusher.status == 'success'){
        this.$root.$refs.master.getCredit()
        this.$root.$snackbar(this.$t('received_credit',{amount: res_pusher.amount}))

        this.$root.$CloseQRcodePromptPay()
        this.$root.$SuccessQRcodePromptPay(res_pusher.account_number,res_pusher.account_name,res_pusher.amount)
        // this.$router.push({
        //   name: 'business.issue-points' , 
        //   query: {
        //     uuid: res_pusher.uuid,
        //     amount: res_pusher.amount,
        //     account_name: res_pusher.account_name,
        //     account_number: res_pusher.account_number,
        //   }
        // })
      }
    });

    // channel.bind('test_webhook', (res_pusher) => {
    //   console.log(res_pusher)
    //   this.$root.$snackbar(res_pusher.msg)
    // });

    this.loading_main = true
    this.axios
      .get('/business/get-bank-account', {})
      .then(response => {
        //console.log(response.data)
        this.loading_main = false
        this.status_varify_business = response.data.business.status_varify_business
        let array = []
        if(response.data.bank_account && response.data.bank_account.length){
          for(let bank of response.data.bank_account){
            array.push({
              name: bank.account_name + ' ('+bank.account_number+')',
              value: bank.uuid
            })
          }
          this.selectBankAccount = response.data.bank_account[0].uuid
        }
        this.bank_account = array
      })
  },
  methods: {
    formatNumber (number) {
      return new Intl.NumberFormat(this.locale.replace('_', '-')).format(number)
    },
    formatCurrency (number, currency) {
      return new Intl.NumberFormat(this.locale.replace('_', '-'), {style: 'currency', currency: currency}).format(number)
    },
    generateQrCode (){
      if(this.QrCodeForm.amount && parseFloat(this.QrCodeForm.amount) > 0 && this.selectBankAccount){
          this.QrCodeForm.loading = true
          let that = this
          this.axios.post('business/bank-account/generate-qr-code', {
              uuid: this.selectBankAccount,
              amount: this.QrCodeForm.amount
          })
          .then(res => {
            if(res.data.status == 'success' && res.data.response_gen_qr && res.data.response_gen_qr.status == 'OK'){
              let account_number = ''
              if(res.data.bank_account && res.data.bank_account.account_number){
                let lastChar = res.data.bank_account.account_number.substr(res.data.bank_account.account_number.length - 4);
                if(res.data.bank_account.type_account == 'MSISDN'){
                  account_number = 'xxx-xxx-' + lastChar
                }
                else if(res.data.bank_account.type_account == 'BANKAC'){
                  account_number = 'xxxx-xxxx-xxxx-' + lastChar
                }
                else if(res.data.bank_account.type_account == 'EWALLETID'){
                  account_number = 'xxx-xxxxxxxx-' + lastChar
                }
                else if(res.data.bank_account.type_account == 'NATID'){
                  let minChar = res.data.bank_account.account_number.substr((res.data.bank_account.account_number.length-3) - 5);
                  account_number = 'x-xxxx-' + minChar + '-xx-x'
                }
                else if(res.data.bank_account.type_account == 'BILLERID'){
                  account_number = res.data.bank_account.type_account
                }
                else if(res.data.bank_account.type_account == 'TAXID'){
                  account_number = res.data.bank_account.type_account
                }
              }
              let array_text = [{
                id: 1,
                title: this.$t('scan_qr_for_transfer'),
                title_class: 'text_scan_qr',
                text: ''
              },{
                id: 2,
                title: this.$t('name') + ':',
                title_class: '',
                text: res.data.bank_account ? res.data.bank_account.account_name : ''
              },{
                id: 3,
                title: this.$t('account_number') + ':',
                title_class: '',
                text: account_number
              }]
              that.$root.$QRcodePromptPay('QRCode Promptpay', res.data.response_gen_qr.qr_code, array_text, { color: 'red' })
            }
            else{
              that.$root.$snackbar(this.$t('not_found'))
            }
          })
          .catch(err => {
            if (err.response.data.status === 'error') {
              that.$root.$snackbar(err.response.data.msg)
            }
          })
          .finally(() => that.QrCodeForm.loading = false)
      }
      else{
        this.$root.$snackbar(this.$t('invalid_value'))
      }
    },
    generateQrCodeRedemgo (){
      if(this.status_varify_business && this.QrCodeForm.amount && parseFloat(this.QrCodeForm.amount) > 0){
          this.QrCodeForm.loading = true
          let that = this
          this.axios.post('business/bank-account/generate-qr-code-redemgo', {
              amount: this.QrCodeForm.amount
          })
          .then(res => {
            if(res.data.status == 'success' && res.data.response_gen_qr && res.data.response_gen_qr.status == 'OK'){
              let array_text = [{
                id: 1,
                title: this.$t('scan_qr_for_transfer'),
                title_class: 'text_scan_qr',
                text: ''
              },{
                id: 2,
                title: this.$t('name') + ':',
                title_class: '',
                text: res.data.response_gen_qr.name
              },{
                id: 3,
                title: this.$t('account_number') + ':',
                title_class: '',
                text: res.data.response_gen_qr.biller_id
              }]
              that.$root.$QRcodePromptPay('QRCode Promptpay', res.data.response_gen_qr.qr_code, array_text, { color: 'red' })
            }
            else{
              that.$root.$snackbar(this.$t('not_found'))
            }
          })
          .catch(err => {
            if (err.response.data.status === 'error') {
              that.$root.$snackbar(err.response.data.msg)
            }
          })
          .finally(() => that.QrCodeForm.loading = false)
      }
      else{
        this.$root.$snackbar(this.$t('invalid_value'))
      }
    }
  },
}
</script>
<style>
/* body {
  height: 100vh;
  width: 100vw;
  background: #1939f6;
  overflow: hidden;
}

* {
  box-sizing: border-box;
} */
.disable_div{
  pointer-events: none;
  opacity: 0.4;
}
</style>