<template>
  <div class="viewContainer" >
    <v-container
      class="pa-4 pa-sm-8"
    >
      <v-card v-if="loading_main" class="content-tabs-wrapper pa-4 pa-sm-8 text-center" elevation="0">
        <v-progress-circular
          :size="32"
          color="primary"
          indeterminate
          class="ma-12"
        ></v-progress-circular>
      </v-card>
      <v-card v-if="!loading_main" class="content-tabs-wrapper px-4 px-sm-8 py-4 py-sm-8" elevation="0">
        <v-tabs
          v-model="tab_dashbroad"
          :color="($store.state.app.dark) ? 'white' : 'black'"
          class="content-tabs mb-8"
          background-color="transparent"
          fixed-tabs
          show-arrows
        >
          <v-tabs-slider></v-tabs-slider>
          <v-tab @click="reloadData(6,'week'); tab_dashbroad='last_1_week';" :href="'#last_1_week'" class="no-caps" :ripple="false">
            {{ $t('last_1_week') }}
          </v-tab>
          <v-tab @click="reloadData(1,'month'); tab_dashbroad='last_1_month';" :href="'#last_1_month'" class="no-caps" :ripple="false">
            {{ $t('last_1_month') }}
          </v-tab>
          <v-tab @click="reloadData(3,'month'); tab_dashbroad='last_3_month';" :href="'#last_3_month'" class="no-caps" :ripple="false">
            {{ $t('last_3_month') }}
          </v-tab>
          <v-tab @click="reloadData(6,'month'); tab_dashbroad='last_6_month';" :href="'#last_6_month'" class="no-caps" :ripple="false">
            {{ $t('last_6_month') }}
          </v-tab>
          <v-tab @click="reloadData(1,'year'); tab_dashbroad='last_1_year';" :href="'#last_1_year'" class="no-caps" :ripple="false">
            {{ $t('last_1_year') }}
          </v-tab>
        </v-tabs>
        <v-row>
          <v-col 
            cols="12" 
            sm="12"    
            md="4"
          >
            <v-card outlined>
              <v-list-item three-line>
                <v-list-item-icon
                  size="48"
                  color="grey"
                  class="mr-4"
                >
                  <v-icon large>
                    mdi-account-outline
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="text-h6 mb-1">
                    {{ total_customer }}
                  </v-list-item-title>
                  <v-list-item-subtitle>{{ $t('total_customer') }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-card> 
          </v-col>
          <v-col 
            cols="12" 
            sm="12"    
            md="4"
          >
            <v-card outlined>
              <v-list-item three-line>
                <v-list-item-icon
                  size="48"
                  color="grey"
                  class="mr-4"
                >
                  <v-icon large>
                    mdi-trophy-variant-outline
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="text-h6 mb-1">
                    {{ total_purchase }}
                  </v-list-item-title>
                  <v-list-item-subtitle>{{ $t('total_purchase') }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-card> 
          </v-col>
          <v-col 
            cols="12" 
            sm="12"    
            md="4"
          >
            <v-card outlined>
              <v-list-item three-line>
                <v-list-item-icon
                  size="48"
                  color="grey"
                  class="mr-4"
                >
                  <v-icon large>
                    mdi-wallet-giftcard
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="text-h6 mb-1">
                    {{ total_discount }}
                  </v-list-item-title>
                  <v-list-item-subtitle>{{ $t('total_discount') }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-card> 
          </v-col>
        </v-row>
      </v-card>
      <v-card v-if="!loading_main" class="content-tabs-wrapper px-4 px-sm-8 py-4 py-sm-8 mt-4 mt-sm-8" elevation="0">
        <div class="text-h6 mb-4">{{ $t('quantity_customer') }}</div>
        <Bar
          :chart-options="chartOptionsCountCustomer"
          :chart-data="chartDataCountCustomer"
        />
      </v-card>
      <v-card v-if="!loading_main" class="content-tabs-wrapper px-4 px-sm-8 py-4 py-sm-8 mt-4 mt-sm-8" elevation="0">
        <div class="text-h6 mb-4">{{ $t('purchase_customer') }}</div>
        <Bar
          :chart-options="chartOptionsPurchase"
          :chart-data="chartDataPurchase"
        />
      </v-card>
      <v-card v-if="!loading_main" class="content-tabs-wrapper px-4 px-sm-8 py-4 py-sm-8 mt-4 mt-sm-8" elevation="0">
        <div class="text-h6 mb-4">{{ $t('discount_customer') }}</div>
        <Bar
          :chart-options="chartOptionsDiscount"
          :chart-data="chartDataDiscount"
        />
      </v-card>
      <div v-if="!loading_main" class="mt-4 mt-sm-8">
        <v-row no-gutters>
          <v-col cols md="6" class="pr-md-4">
            <v-card class="content-tabs-wrapper px-4 px-sm-8 py-4 py-sm-8" elevation="0">
              <div class="text-h6 mb-4">{{ $t('gender_customer') }}</div>
              <Pie
                :chart-options="chartOptions"
                :chart-data="chartDataGender"
              />
            </v-card>
          </v-col>
          <v-col cols md="6" class="pl-md-4 mt-4 mt-sm-8 mt-md-0">
            <v-card class="content-tabs-wrapper px-4 px-sm-8 py-4 py-sm-8" elevation="0">
              <div class="text-h6 mb-4">{{ $t('age_customer') }}</div>
              <Pie
                :chart-options="chartOptions"
                :chart-data="chartDataAge"
              />
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>
<script>
import { Bar , Pie } from 'vue-chartjs/legacy'

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  ArcElement
} from 'chart.js'

ChartJS.register(
  Title, 
  Tooltip, 
  Legend, 
  BarElement, 
  CategoryScale, 
  LinearScale, 
  LineElement, 
  PointElement,
  ArcElement
)

export default {
  components: { 
    Bar,
    Pie 
  },
  data: () => ({
    locale: 'th',
    tab: 'tab-0',
    tabImg: 'about:blank',
    tabImgHeight: null,
    tabImgDefaultHeight: 780,
    tabImgAspectRation: 1.7778,
    tabImgs: [],
    loading: true,
    loading_main: true,
    countCustomerOptions: {},
    purchaseOptions: {},
    discountOptions: {},
    genderOptions: {},
    ageOptions: {},
    total_customer: 0,
    total_purchase: 0,
    total_discount: 0,
    tab_dashbroad: 'last_1_year',
    chartDataCountCustomer: {},
    chartOptionsCountCustomer: {
      responsive: true,
      maintainAspectRatio: false,
    },
    chartDataPurchase: {},
    chartOptionsPurchase: {
      responsive: true,
      maintainAspectRatio: false,
    },
    chartDataDiscount: {},
    chartOptionsDiscount: {
      responsive: true,
      maintainAspectRatio: false,
    },
    chartDataAge: {},
    chartDataGender: {},
    chartOptions: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        tooltip: {
          callbacks: {
            label: function(context) {
              return ' ' + context.label + ': ' + context.formattedValue;
            }
          }
        },
        legend: {
          labels: {
            color: 'rgba(0, 0, 0, 0.87)'
          },
        },
      },
    }
  }),
  created () {
    // Set locale
    this.locale = 'th'
    this.moment.locale(this.locale.substr(0,2))

    this.reloadData(1,'year')
  },
  methods: {
    formatNumber (number) {
      return new Intl.NumberFormat(this.locale.replace('_', '-')).format(number)
    },
    formatCurrency (number) {
      return new Intl.NumberFormat(this.locale.replace('_', '-'), {style: 'currency', currency: this.business.currency}).format(number)
    },
    formatDate (dt, format) {
      if (dt === null) {
        dt = '-'
      } else {
        dt = (format == 'ago') ? this.moment(dt).fromNow() : this.moment(dt).format(format)
      }
      return dt
    },
    reloadData (date,format){
      this.loading_main = true
      this.axios
      .post('/business/dashboard', { 
        format: format,
        date: date
      })
      .then(res => {
        if (res.data.status === 'success') {
          //console.log(res.data)
          let that = this
          this.total_customer = res.data.sum_customer
          this.total_purchase = this.formatNumber(parseFloat(res.data.sum_purchase))
          this.total_discount = this.formatNumber(parseFloat(res.data.sum_discount))
          let currency = res.data.currency

        
          let labels_count_customer = []
          let data_count_customer = []
          let array_count_customer = res.data.array_count_customer
          let max_count_customer = 0
          for(let e in array_count_customer){
            labels_count_customer.push(e)
            data_count_customer.push(array_count_customer[e])
            if(parseFloat(array_count_customer[e]) > max_count_customer){
              max_count_customer = parseFloat(array_count_customer[e])
            }
          }
          this.chartDataCountCustomer = {
            labels: labels_count_customer,
            datasets: [
              {
                label: this.$t('quantity_customer'),
                backgroundColor: '#1abc9c',
                barThickness: 24, // Width of the bars in pixels
                data: data_count_customer
              }
            ]
          }
          let labels_purchase = []
          let data_purchase = []
          let array_count_purchase = res.data.array_count_purchase
          let max_count_purchase = 0
          for(let e in array_count_purchase){
            labels_purchase.push(e)
            data_purchase.push(array_count_purchase[e])
            if(parseFloat(array_count_purchase[e]) > max_count_purchase){
              max_count_purchase = parseFloat(array_count_purchase[e])
            }
          }
          this.chartDataPurchase = {
            labels: labels_purchase,
            datasets: [
              {
                label: this.$t('purchase_customer'),
                backgroundColor: '#e67e22',
                barThickness: 24, // Width of the bars in pixels
                data: data_purchase
              }
            ]
          }

          let labels_discount = []
          let data_discount = []
          let array_count_discount = res.data.array_count_discount
          let max_count_discount = 0
          for(let e in array_count_discount){
            labels_discount.push(e)
            data_discount.push(array_count_discount[e])
            if(parseFloat(array_count_discount[e]) > max_count_discount){
              max_count_discount = parseFloat(array_count_discount[e])
            }
          }

          // console.log(max_count_customer)
          // console.log(max_count_purchase)
          // console.log(max_count_discount)

          this.chartDataDiscount = {
            labels: labels_discount,
            datasets: [
              {
                label: this.$t('discount_customer'),
                backgroundColor: '#e74c3c',
                barThickness: 24, // Width of the bars in pixels
                data: data_discount
              }
            ]
          }

          this.chartOptionsCountCustomer = {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              tooltip: {
                callbacks: {
                  label: function(context) {
                    return ' ' + context.formattedValue + ' ' + that.$t('item_user');
                  }
                }
              },
              legend: {
                labels: {
                  color: this.getYAxisTicksColor(),
                },
              },
            },
            scales: {
              // yAxes: [{
              //   ticks: {
              //     beginAtZero: true,          
              //     stepSize: 1       
              //   }
              // }],
              // xAxes: [{
              //   ticks: {
              //     maxTicksLimit: 30
              //   }
              // }],
              x: {
                ticks: {
                  color: this.getYAxisTicksColor(),
                },
                title: {
                  color: this.getYAxisTicksColor(),
                },
              },
              y: {
                ticks: {
                  color: this.getYAxisTicksColor(),
                  beginAtZero: true,          
                  stepSize: (max_count_customer <= 10 ? 1 : false) 
                },
                title: {
                  color: this.getYAxisTicksColor(),
                },
              },
            },
            elements: {
              bar: {
                borderRadius: 24, // Border radius for bars
              },
            },
          }

          this.chartOptionsPurchase = {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              tooltip: {
                callbacks: {
                  label: function(context) {
                    return ' ' + context.formattedValue + ' ' + currency;
                  }
                }
              },
              legend: {
                labels: {
                  color: this.getYAxisTicksColor(),
                },
              },
            },
            scales: {
              x: {
                ticks: {
                  color: this.getYAxisTicksColor(),
                },
                title: {
                  color: this.getYAxisTicksColor(),
                },
              },
              y: {
                ticks: {
                  color: this.getYAxisTicksColor(),
                  beginAtZero: true, 
                  stepSize: (max_count_purchase <= 10 ? 1 : false) 
                },
                title: {
                  color: this.getYAxisTicksColor(),
                },
              },
            },
            elements: {
              bar: {
                borderRadius: 24, // Border radius for bars
              },
            },
          }

          this.chartOptionsDiscount = {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              tooltip: {
                callbacks: {
                  label: function(context) {
                    return ' ' + context.formattedValue + ' ' + currency;
                  }
                }
              },
              legend: {
                labels: {
                  color: this.getYAxisTicksColor(),
                },
              },
            },
            scales: {
              x: {
                ticks: {
                  color: this.getYAxisTicksColor(),
                },
                title: {
                  color: this.getYAxisTicksColor(),
                },
              },
              y: {
                ticks: {
                  color: this.getYAxisTicksColor(),
                  beginAtZero: true, 
                  stepSize: (max_count_discount <= 10 ? 1 : false)   
                },
                title: {
                  color: this.getYAxisTicksColor(),
                },
              },
            },
            elements: {
              bar: {
                borderRadius: 24, // Border radius for bars
              },
            },
          }

          this.chartDataGender = {
            labels: [
              this.$t('male'),
              this.$t('female'),
              this.$t('no_gender')
            ],
            datasets: [
              {
                backgroundColor: [
                  '#3498db', 
                  '#fd79a8', 
                  this.getNoDataColor(),
                ],
                data: [
                  res.data.sum_gender_male,
                  res.data.sum_gender_female,
                  res.data.sum_gender_no_gender
                ],
                borderColor: this.getBorderColor(), // Border colors for segments
                borderWidth: 2, // Border width
              }
            ]
          }

          let sum_age = res.data.sum_age
          let labels_age = []
          let data_age = []

          for(let age in sum_age){
            if(age == 'no_age'){
              labels_age.push(this.$t(age))
            }
            else{
              labels_age.push(age)
            }
            data_age.push(sum_age[age])
          }
          this.chartDataAge = {
            labels: labels_age,
            datasets: [
              {
                backgroundColor: [
                  '#83B9AB', 
                  '#FFD39D', 
                  '#F6B366', 
                  '#EF805D',
                  '#B93983',
                  '#91BC54',
                  this.getNoDataColor(),
                ],
                data: data_age,
                borderColor: this.getBorderColor(), // Border colors for segments
                borderWidth: 2, // Border width
              }
            ]
          }
        }
      })
      .catch(err => {
        if (err.response.data.status === 'error') {
          this.$root.$snackbar(err.response.data.msg)
        }
      })
      .finally(() => {
        this.loading_main = false
      })
    },
    getYAxisTicksColor() {
      return this.$vuetify.theme.dark ? 'rgba(255, 255, 255, .7)' : 'rgba(0, 0, 0, 0.87)'
    },
    getBorderColor() {
      return this.$vuetify.theme.dark ? '#232425' : '#ffffff'
    },
    getNoDataColor() {
      return this.$vuetify.theme.dark ? '#454647' : '#f4f4f4'
    },
  },
  mounted() {
    // Register a custom font family
    ChartJS.defaults.font.family = 'Kanit';

    // Load your custom font styles
    const customFontStyles = new FontFace('Kanit', 'url(https://fonts.googleapis.com/css?family=Kanit)');
    customFontStyles.load().then(() => {
      document.fonts.add(customFontStyles);

      // Render the chart
      this.reloadData(1,'year')
    });
  },
  computed: {

  },
  watch: {
    '$vuetify.theme.dark': {
      immediate: true, // Trigger the watcher immediately on component mount
      handler(newTheme) {
        if (this.chartOptionsCountCustomer.scales) {
          this.chartOptionsCountCustomer.scales.x.ticks.color = this.getYAxisTicksColor();
          this.chartOptionsCountCustomer.scales.x.title.color = this.getYAxisTicksColor();
          this.chartOptionsCountCustomer.scales.y.ticks.color = this.getYAxisTicksColor();
          this.chartOptionsCountCustomer.scales.y.title.color = this.getYAxisTicksColor();
          this.chartOptionsCountCustomer.plugins.legend.labels.color = this.getYAxisTicksColor();
        }

        if (this.chartOptionsPurchase.scales) {
          this.chartOptionsPurchase.scales.x.ticks.color = this.getYAxisTicksColor();
          this.chartOptionsPurchase.scales.x.title.color = this.getYAxisTicksColor();
          this.chartOptionsPurchase.scales.y.ticks.color = this.getYAxisTicksColor();
          this.chartOptionsPurchase.scales.y.title.color = this.getYAxisTicksColor();
          this.chartOptionsPurchase.plugins.legend.labels.color = this.getYAxisTicksColor();
        }

        if (this.chartOptionsDiscount.scales) {
          this.chartOptionsDiscount.scales.x.ticks.color = this.getYAxisTicksColor();
          this.chartOptionsDiscount.scales.x.title.color = this.getYAxisTicksColor();
          this.chartOptionsDiscount.scales.y.ticks.color = this.getYAxisTicksColor();
          this.chartOptionsDiscount.scales.y.title.color = this.getYAxisTicksColor();
          this.chartOptionsDiscount.plugins.legend.labels.color = this.getYAxisTicksColor();
        }

        if (this.chartOptions.plugins.legend) {
          this.chartOptions.plugins.legend.labels.color = this.getYAxisTicksColor();
        }

        if (this.chartDataGender.datasets) {
          this.chartDataGender.datasets[0].borderColor = this.getBorderColor();
          this.chartDataGender.datasets[0].backgroundColor[this.chartDataGender.datasets[0].backgroundColor.length - 1] = this.getNoDataColor();
        }

        if (this.chartDataAge.datasets) {
          this.chartDataAge.datasets[0].borderColor = this.getBorderColor();
          this.chartDataAge.datasets[0].backgroundColor[this.chartDataAge.datasets[0].backgroundColor.length - 1] = this.getNoDataColor();
        }
      },
    },
  },
}
</script>