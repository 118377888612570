<template>
  <div class="viewContainer" >
    <v-container
      class="pa-4 pa-sm-8"
    >
      <v-card v-if="loading_main" class="content-tabs-wrapper pa-4 pa-sm-8 text-center" elevation="0">
        <v-progress-circular
          :size="32"
          color="primary"
          indeterminate
          class="ma-12"
        ></v-progress-circular>
      </v-card>
      <v-card v-if="!loading_main" class="content-tabs-wrapper px-4 px-sm-8 py-4 py-sm-8" elevation="0">
        <div class="content-container">
          <div class="d-flex justify-content-between align-center mb-8">
            <div class="text-h6">{{ $t('issue_points') }}</div>
            <v-switch
              v-model="switch_auto_verify_slip"
              color="primary"
              flat
              :label="$t('auto_verify_slip')"
              :disabled="$auth.user().role == 3 ? false : true"
              @click="ChangeStatusAutoVerifySlip"
              :hide-details="true"
              class="mt-0 pt-0"
              :loading="loading_switch_auto_verify_slip"
              inset
              dense
              :ripple="false"
            ></v-switch>
          </div>

          <v-alert
            :value="issuePointsForm.success"
            text
            type="success"
            icon="mdi-check-circle-outline"
            class="mb-4"
          >
          <i18n path="issue_points_success">
            <template #customerNumber>{{ msg.customerNumber }}</template>
            <template #points><span class="font-weight-medium">{{ msg.points }}</span></template>
            <template #value><span class="font-weight-medium">{{ msg.value }}</span></template>
            <template #transactions>
              <router-link class="inherit--text" :to="{ name: 'business.transactions' }"><span class="font-weight-medium">{{ $t('transactions').toLowerCase() }}</span></router-link>
            </template>
          </i18n>
          </v-alert>

          <div class="d-flex justify-content-between align-center">
            <div class="text-subtitle-1">{{ $t('verify_slip_quantity') }}</div>
            <div class="text-h6"><span :class="font_color">{{ verify_slip_used }}</span> / <span class="">{{ verify_slip }}</span></div>
          </div>
          <div v-if="package_now > 1" class="text-caption"> ( {{ $t('expires') }}: {{ formatDate(verify_slip_expired, 'LL') }} ) </div>

          <v-divider class="my-4" />
          <div class="text-subtitle-1 mb-4">{{ $t('issue_points_head') }}</div>
          <ValidationObserver ref="verifyCustomerNumberForm" v-slot="{ invalid }">
            <v-form 
              id="verifyCustomerNumberForm"
              lazy-validation
              @submit.prevent="submitAmount()"
              autocomplete="off"
              method="post"
              accept-charset="UTF-8"
            >
            <v-card v-if="loading" flat tile class="content-tabs-card pa-5 mb-0 mb-md-12">
              <v-progress-linear :indeterminate="true" color="primary"></v-progress-linear>
            </v-card>
            <div v-if="!loading">
              <qrcode-capture @detect="onDetect" capture="environment" :multiple="false" id="qrScanner" tabindex="-1" class="d-none"/>
              <x-text-field
                type="number"
                autofocus
                v-model="issuePointsForm.amount"
                ref="issuePointsForm.amount"
                id="issuePointsForm.amount"
                :name="$t('amount')"
                :suffix="business.currency"
                :step="business.currency_step"
                :rules="'required'"
                :hint="purchaseAmount + ' = ' + pointsIssued + ' ' + $t('points').toLowerCase()"
                persistent-hint
                prepend-inner-icon="mdi-qrcode"
                :loading="inputLoading"
                @click:prepend-inner="openQrCamera"
                :label="$t('issue_points_amount')"
              />

              <v-btn
                type="submit"
                depressed 
                block
                rounded
                x-large
                color="primary"
                class="mt-4 no-caps"
                :loading="verifyCustomerNumberForm.loading" :disabled="verifyCustomerNumberForm.loading || invalid"
              >
                {{ $t('issue_points') }} <v-icon right>mdi-arrow-right</v-icon>
              </v-btn>

              <p class="text-subtitle-1 mt-4 mb-0">
                <i18n path="issue_points_configure_rules">
                  <template #configure_the_rules>
                    <router-link :to="{ name: 'business.rules' }">{{ $t('configure_the_rules').toLowerCase() }}</router-link>
                  </template>
                </i18n>
              </p>
            </div>
            </v-form>
          </ValidationObserver>
          <p v-if="!business.status_reward_range" class="mt-0 mb-0 text-subtitle-1">
              {{ $t('scenario_issue', {currency: business.currency,points: business.points_per_currency}) }}
          </p>
          <v-card v-if="business.status_reward_range" outlined class="mt-8">
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      {{ $t('min') }} ({{ business.currency }})
                    </th>
                    <th class="text-left">
                      {{ $t('max') }} ({{ business.currency }})
                    </th>
                    <th class="text-left">
                      {{ $t('points') }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in business.array_reward_range"
                    :key="item.point"
                  >
                    <td>{{ formatNumber(item.min) }}</td>
                    <td>{{ formatNumber(item.max) }}</td>
                    <td>{{ formatNumber(item.point) }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>


          <!-- <v-card-text>
            <v-alert
              :value="issuePointsForm.success"
              type="success"
              class="mb-5"
            >
              <i18n path="issue_points_success">
                <template #customerNumber>
                  {{ msg.customerNumber }}
                </template>
                <template #points>
                  {{ msg.points }}
                </template>
                <template #value>
                  {{ msg.value }}
                </template>
                <template #transactions>
                  <router-link class="white--text" :to="{ name: 'business.transactions' }">{{ $t('transactions').toLowerCase() }}</router-link>
                </template>
              </i18n>
            </v-alert>

            <p class="fg--text subtitle-1 font-weight-medium">
              <v-card v-if="loading_switch_auto_verify_slip" flat tile class="content-tabs-card pa-5 mb-0 mb-md-12">
                <v-progress-linear :indeterminate="true" color="primary"></v-progress-linear>
              </v-card>
              <v-switch
                v-if="!loading_switch_auto_verify_slip"
                v-model="switch_auto_verify_slip"
                color="success"
                flat
                :label="$t('auto_verify_slip')"
                :disabled="$auth.user().role == 3 ? false : true"
                @click="ChangeStatusAutoVerifySlip"
              ></v-switch>
            </p>
            <p class="fg--text subtitle-1">
              {{ $t('verify_slip_quantity') }} <span :class="font_color">{{ verify_slip_used }}</span> / <span class="font-blue">{{ verify_slip }}</span>
            </p>
            <p v-if="package_now > 1" class="fg--text subtitle-1"> ( {{ $t('expires') }}: {{ formatDate(verify_slip_expired, 'LL') }} ) </p>
            <p class="fg--text subtitle-1 font-weight-medium">
              <i18n path="issue_points_configure_rules">
                <template #configure_the_rules>
                  <router-link :to="{ name: 'business.rules' }">{{ $t('configure_the_rules').toLowerCase() }}</router-link>
                </template>
              </i18n>
            </p>
            <p class="fg--text subtitle-1">{{ $t('issue_points_head') }}</p>
            <ValidationObserver ref="verifyCustomerNumberForm" v-slot="{ invalid }">
              <v-form 
                id="verifyCustomerNumberForm"
                lazy-validation
                @submit.prevent="submitAmount()"
                autocomplete="off"
                method="post"
                accept-charset="UTF-8"
              >
              <v-card v-if="loading" flat tile class="content-tabs-card pa-5 mb-0 mb-md-12">
                <v-progress-linear :indeterminate="true" color="primary"></v-progress-linear>
              </v-card>
                <v-row v-if="!loading" no-gutters>
                  <v-col cols="8">
                    <qrcode-capture @detect="onDetect" capture="environment" :multiple="false" id="qrScanner" tabindex="-1" class="d-none"/>
                    <x-text-field
                        type="number"
                        autofocus
                        v-model="issuePointsForm.amount"
                        ref="issuePointsForm.amount"
                        id="issuePointsForm.amount"
                        :name="$t('amount')"
                        :suffix="business.currency"
                        :step="business.currency_step"
                        :rules="'required'"
                        :hint="purchaseAmount + ' = ' + pointsIssued + ' ' + $t('points').toLowerCase()"
                        persistent-hint
                        prepend-inner-icon="mdi-qrcode"
                        :loading="inputLoading"
                        @click:prepend-inner="openQrCamera"
                      />
                  </v-col>
                  <v-col cols="4">
                    <v-btn
                      type="submit"
                      depressed 
                      tile
                      block
                      color="ctaBg ctaFg--text"
                      class="title ma-0 no-caps"
                      height="56px"
                      :loading="verifyCustomerNumberForm.loading" :disabled="verifyCustomerNumberForm.loading || invalid"
                    >
                      {{ $t('issue_points') }} <v-icon right>mdi-arrow-right</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </ValidationObserver>
            <p v-if="!business.status_reward_range" class="mt-0 mb-0 fg--text subtitle-1">
                {{ $t('scenario_issue', {currency: business.currency,points: business.points_per_currency}) }}
            </p>
            <v-simple-table v-if="business.status_reward_range">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      {{ $t('min') }} ({{ business.currency }})
                    </th>
                    <th class="text-left">
                      {{ $t('max') }} ({{ business.currency }})
                    </th>
                    <th class="text-left">
                      {{ $t('points') }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in business.array_reward_range"
                    :key="item.point"
                  >
                    <td>{{ formatNumber(item.min) }}</td>
                    <td>{{ formatNumber(item.max) }}</td>
                    <td>{{ formatNumber(item.point) }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            
          </v-card-text> -->
        </div>
      </v-card>
    </v-container>

    <!-- <v-dialog v-model="dialogIssuePoints" persistent max-width="320">
      <ValidationObserver ref="issuePointsForm" v-slot="{ invalid }">
        <v-form 
          id="issuePointsForm"
          lazy-validation
          @submit.prevent="issuePoints()"
          autocomplete="off"
          method="post"
        >
          <v-card>
            <v-card-title class="headline">{{ $t('issue_points') }}</v-card-title>
            <v-card-text>
              <p class="body-1">{{ $t('enter_amount_of_purchase') }}</p>
              <x-text-field
                type="number"
                autofocus
                v-model="issuePointsForm.amount"
                ref="issuePointsForm.amount"
                id="issuePointsForm.amount"
                :name="$t('amount')"
                :suffix="business.currency"
                :step="business.currency_step"
                :rules="'required|between:1,100000'"
                :hint="purchaseAmount + ' = ' + pointsIssued + ' ' + $t('points').toLowerCase()"
                persistent-hint
              />
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="secondary" class="no-caps" text @click="dialogIssuePoints = false">{{ $t('close') }}</v-btn>
              <v-btn color="NavBg NavFg--text" class="no-caps" depressed :loading="issuePointsForm.loading" :disabled="issuePointsForm.loading || invalid" type="submit">{{ $t('issue_points') }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </ValidationObserver>
    </v-dialog> -->
  </div>
</template>
<script>
import { supportsQrCamera } from '@/utils/helpers'
import { QrcodeCapture } from 'vue-qrcode-reader'
import { mask } from 'vue-the-mask'
// import MenuBusiness from '@/components/ui/MenuBusiness.vue'
export default {
  components: {
    QrcodeCapture,
    //MenuBusiness
  },
  data: () => ({
    dialogIssuePoints: false,
    font_color: 'font-green',
    locale: 'th',
    tab: 'tab-0',
    tabImg: 'about:blank',
    verify_slip_used: 0,
    verify_slip: 0,
    package_now: null,
    verify_slip_expired: null,
    tabImgHeight: null,
    upload_slip: null,
    loading: false,
    loading_switch_auto_verify_slip: true,
    switch_auto_verify_slip: false,
    tabImgDefaultHeight: 780,
    tabImgAspectRation: 1.7778,
    tabImgs: [],
    inputLoading: false,
    qr_redemgo_amount: false,
    qr_redemgo_uuid: false,
    msg: {
      points: 0,
      value: 0,
      customerNumber: ''
    },
    verifyCustomerNumberForm: {
      loading: false,
      customerNumber: ''
    },
    issuePointsForm: {
      loading: false,
      success: false,
      amount: ''
    },
    business: {
      currency: 'USD',
      currency_step: 0.01,
      points_per_currency: null,
      point_value: null,
      points_expiration_months: null,
      min_points_per_purchase: null,
      max_points_per_purchase: null,
      min_points_per_redemption: null,
      max_points_per_redemption: null,
      status_reward_range: 0,
      array_reward_range: null
    },
    loading_main: true,
  }),
  directives: {
    mask
  },
  created () {
    // Set locale
    //console.log(this.$init.config.schemeAndHost)
    this.locale = 'th'
    this.moment.locale(this.locale.substr(0,2))
    let uuid = this.$auth.user().business_uuid ? this.$auth.user().business_uuid : this.$auth.user().uuid
    var channel = this.$pusher.subscribe(uuid);
    //console.log(channel)
    // channel.bind('webhook_qr_redemgo', (res_pusher) => {
    //   console.log(res_pusher)
    //   if(res_pusher && res_pusher.status == 'success'){
    //     this.$root.$refs.master.getCredit()
    //     this.$root.$snackbar(this.$t('received_credit',{amount: res_pusher.amount}))
    //     this.$root.$SuccessQRcodePromptPay(res_pusher.account_number,res_pusher.account_name,res_pusher.amount)
    //   }
    // });

    
    // Query object
    this.verifyCustomerNumberForm.customerNumber = this.$route.query.c
    this.loading_main = true
    this.axios
      .get('/business/settings', { params: { locale: this.$i18n.locale }})
      .then(response => {
        this.loading_main = false
        let business = response.data.business
        this.business.currency = business.currency
        this.business.currency_step = business.currency_step
        this.business.points_per_currency = business.points_per_currency
        this.business.point_value = business.point_value
        this.business.points_expiration_months = business.points_expiration_months
        this.business.min_points_per_purchase = business.min_points_per_purchase
        this.business.max_points_per_purchase = business.max_points_per_purchase
        this.business.min_points_per_redemption = business.min_points_per_redemption
        this.business.max_points_per_redemption = business.max_points_per_redemption
        if(business.status_reward_range){
          this.business.status_reward_range = business.status_reward_range
          this.business.array_reward_range = JSON.parse(business.array_reward_range)
        }
        if(business.is_verify_slip){
          this.switch_auto_verify_slip = true
        }
        if(business.subscription){
          this.verify_slip_used = business.subscription.verify_slip_used
          this.verify_slip = business.subscription.verify_slip
          this.verify_slip_expired = business.subscription.expired_at
          this.package_now = business.subscription.package_id
          if(business.subscription.verify_slip_used >= business.subscription.verify_slip){
            this.font_color = 'font-red'
          }
        }
        this.loading_switch_auto_verify_slip = false

        // if(this.$route.query && this.$route.query.uuid && this.$route.query.amount){
        //   //console.log(this.$route.query)
        //   this.qr_redemgo_amount = this.$route.query.amount
        //   this.qr_redemgo_uuid = this.$route.query.uuid
        //   let amount = this.$route.query.amount
        //   this.issuePointsForm.amount = ''

        //   this.$root.$VerifySlip(
        //     false,
        //     false,
        //     false,
        //     this.business,
        //     false,
        //     true,
        //     amount,
        //     true,
        //     {
        //       uuid: this.$route.query.uuid,
        //       account_number: this.$route.query.account_number,
        //       account_name: this.$route.query.account_name
        //     }
        //   )
        // }

      })
  },
  methods: {
    supportsQrCamera,
    formatNumber (number) {
      return new Intl.NumberFormat(this.locale.replace('_', '-')).format(number)
    },
    formatCurrency (number) {
      return new Intl.NumberFormat(this.locale.replace('_', '-'), {style: 'currency', currency: this.business.currency}).format(number)
    },
    formatDate (dt, format) {
      if (dt === null) {
        dt = '-'
      } else {
        dt = (format == 'ago') ? this.moment(dt).fromNow() : this.moment(dt).format(format)
      }
      return dt
    },
    openQrCamera () {
      this.$el.querySelector('#qrScanner').click()
    },
    onFilePicked (e) {
        const files = e.target.files
        //console.log(files)
        if(files[0] !== undefined) {
          if(files[0].name.lastIndexOf('.') <= 0) {
            return
          }
          const fr = new FileReader ()
          fr.readAsDataURL(files[0])
          fr.addEventListener('load', () => {
            this.upload_slip = files[0]
            //console.log(this.upload_slip)
            //this.onUploadQrcodeReader()
          })
        }
    },
    onUploadQrcodeReader () {
      this.loading = true
      let settings = { headers: { 'content-type': 'multipart/form-data' } }
      let formData = new FormData()
      formData.append('upload_slip', this.upload_slip)
      this.axios.post('/business/upload-slip', formData, settings)
      .then(res => {
        this.loading = false
        if (res.data.status === 'success') {
          if(res.data.type === 'customer_number'){
            this.verifyCustomerNumberForm.customerNumber = res.data.customer_number
          }
          else if(res.data.type === 'verify_slip'){
            this.$root.$VerifySlip(res.data.auto_verify_slip,this.upload_slip,res.data.data_verify_slip,this.business)
          }
        }
      })
      .catch(err => {
        this.loading = false
        if (err.response.data.status === 'error') {
          this.$root.$snackbar(err.response.data.msg)
        }
      })
    },
    submitAmount (){
      if(this.issuePointsForm.amount && parseFloat(this.issuePointsForm.amount) > 0){
        let amount = this.issuePointsForm.amount
        this.issuePointsForm.amount = ''
        this.$root.$VerifySlip(
          false,
          false,
          false,
          this.business,
          false,
          true,
          amount,
          false,
          {}
        )

      }
      else{
        this.$root.$snackbar(this.$t('invalid_value'))
      }
    },
    async onDetect (promise) {
      this.$refs['verifyCustomerNumberForm'].reset()
      this.inputLoading = true
      try {
        const {
          imageData,    // raw image data of image/frame
          content,      // decoded String or null
          location      // QR code coordinates or null
        } = await promise
        //console.log(content)
        //console.log(imageData)
        // const data = this.$promptpay.parse(content)
        this.upload_slip = this.$el.querySelector('#qrScanner').files[0]
        let qrcode = content
        let ref_id = ''
        let bank_id = ''
        if(this.switch_auto_verify_slip){
          if(!qrcode){
            this.$root.$snackbar(this.$t('invalid_slip'))
            this.$root.$OpenQrReader()
            .then((confirm) => {
              if (confirm.confirm) {
                //console.log(confirm)
                qrcode = confirm.qr_code
                const data = this.$promptparse.parse(confirm.qr_code)
                if(data && data.tags && data.tags.length >= 3 && data.tags[0].subTags && data.tags[0].subTags.length >= 3){
                  ref_id = data.tags[0].subTags[2].value
                  bank_id = data.tags[0].subTags[1].value
                  this.submitUploadSlip(qrcode,ref_id,bank_id)
                }
                else{
                  this.$root.$snackbar(this.$t('invalid_slip'))
                }
              }
            })
            return false
          }
          const data = this.$promptparse.parse(qrcode)
          //console.log(data)
          
          if(data && data.tags && data.tags.length >= 3 && data.tags[0].subTags && data.tags[0].subTags.length >= 3){
            ref_id = data.tags[0].subTags[2].value
            bank_id = data.tags[0].subTags[1].value
          }
          
          //console.log(this.$el.querySelector('#qrScanner').files[0])
          
          //console.log(this.upload_slip)
        }
        
        this.submitUploadSlip(qrcode,ref_id,bank_id)

        /*
        const files = this.$el.querySelector('#qrScanner').files
        //console.log(files)
        if(files[0] !== undefined) {
          if(files[0].name.lastIndexOf('.') <= 0) {
            return
          }
          const fr = new FileReader ()
          fr.readAsDataURL(files[0])
          fr.addEventListener('load', () => {
            this.upload_slip = files[0]
            console.log(this.upload_slip)
            //this.onUploadQrcodeReader()
          })
        }
        */
        /*
        if (content === null) {
          this.$refs['verifyCustomerNumberForm.customerNumber'].applyResult({
            errors: this.$t('camera_no_qr_found'),
            valid: false,
            failedRules: {}
          })
        } else {
          const url = new window.URL(content)
          const urlParams = new window.URLSearchParams(url.search)
          this.verifyCustomerNumberForm.customerNumber = urlParams.get('c')
        }
        */
      } catch (error) {
        this.$root.$snackbar(this.$t('invalid_slip'))
      } finally {
        this.inputLoading = false
      }
    },
    submitUploadSlip (qrcode,ref_id,bank_id) {
      this.loading = true
        let settings = { headers: { 'content-type': 'multipart/form-data' } }
        let formData = new FormData()
        formData.append('upload_slip', this.upload_slip)
        formData.append('code', qrcode)
        formData.append('ref_id', ref_id)
        formData.append('bank_id', bank_id)
        this.axios.post('/business/upload-slip', formData, settings)
        .then(res => {
          this.loading = false
          if(res.data.verify_slip_used){
              this.verify_slip_used = res.data.verify_slip_used
          }
          if(res.data.status === 'success') {
            if(res.data.type === 'customer_number'){
              this.verifyCustomerNumberForm.customerNumber = res.data.customer_number
            }
            else if(res.data.type === 'verify_slip'){
              if(res.data.msg_verify_slip_error){
                this.$root.$snackbar(res.data.msg_verify_slip_error)
              }
              this.$root.$VerifySlip(
                res.data.auto_verify_slip,
                this.upload_slip,
                res.data.data_verify_slip,
                this.business,
                res.data.log_varify_slip_uuid,
                false,
                res.data.data_verify_slip.amount,
                false,
                {}
              )
            }
          }
        })
        .catch(err => {
          this.loading = false
          if (err.response.data.status === 'error') {
            if(err.response.data.verify_slip_used){
              this.verify_slip_used = err.response.data.verify_slip_used
            }
            this.$root.$snackbar(err.response.data.msg)
          }
        })
    },
    verifyCustomerNumber () {
      this.verifyCustomerNumberForm.loading = true
      this.axios
        .post('/business/issue/verify-customer-number', {
          locale: this.locale,
          customerNumber: this.verifyCustomerNumberForm.customerNumber
        })
        .then(response => {
          if (response.data.status === 'success') {
            this.$nextTick(() => this.$refs['verifyCustomerNumberForm'].reset())
            this.dialogIssuePoints = true
          }
        })
        .catch(error => {
          if (error.response.data.status === 'error') {
            this.errorMsg = error.response.data.error
            let errors = error.response.data.errors || []
            for (let field in errors) {
              this.$refs['verifyCustomerNumberForm.' + field].applyResult({
                errors: errors[field],
                valid: false,
                failedRules: {}
              })
            }
          }
        })
        .finally(() => {
          this.verifyCustomerNumberForm.loading = false
        })
    },
    issuePoints () {
      this.issuePointsForm.loading = true
      this.axios
        .post('/business/issue/amount', {
          locale: this.locale,
          customerNumber: this.verifyCustomerNumberForm.customerNumber,
          amount: this.issuePointsForm.amount
        })
        .then(response => {
          if (response.data.status === 'success') {
            this.$nextTick(() => this.$refs['issuePointsForm'].reset())
            this.dialogIssuePoints = false
            this.issuePointsForm.success = true
            this.verifyCustomerNumberForm.customerNumber = ''
            this.issuePointsForm.amount = ''
            this.msg.points = this.formatNumber(response.data.points)
            this.msg.value = this.formatCurrency(response.data.value)
            this.msg.customerNumber = response.data.customer_number
          }
        })
        .catch(error => {
          if (error.response.data.status === 'error') {
            this.errorMsg = error.response.data.error
            let errors = error.response.data.errors || []
            for (let field in errors) {
              this.$refs['issuePointsForm.' + field].applyResult({
                errors: errors[field],
                valid: false,
                failedRules: {}
              })
            }
          }
        })
        .finally(() => { 
          this.issuePointsForm.loading = false
        })
    },
    ChangeStatusAutoVerifySlip() {
      //console.log(this.switch_auto_verify_slip)
      this.loading_switch_auto_verify_slip = true
      let settings = { headers: { 'content-type': 'multipart/form-data' } }
      let formData = new FormData()
      formData.append('is_verify_slip', (this.switch_auto_verify_slip ? 1 : 0))
      this.axios.post('/business/change-status-autoVerify-slip', formData, settings)
      .then(res => {
        this.loading_switch_auto_verify_slip = false
        if (res.data.status === 'success') {
          this.$root.$snackbar(this.$t('update_success'))
        }
      })
      .catch(err => {
        this.loading_switch_auto_verify_slip = false
        if (err.response.data.status === 'error') {
          if(err.response.data.type == 'verify_slip_used_full'){
            this.switch_auto_verify_slip = false
          }
          else{
            this.switch_auto_verify_slip = this.switch_auto_verify_slip ? 0 : 1
          }
          this.$root.$snackbar(err.response.data.msg)
        }
      })
    },
  },
  computed: {
    purchaseAmount: function () {
      return this.formatCurrency(this.issuePointsForm.amount)
    },
    pointsIssued: function () {
      let amount = this.issuePointsForm.amount ? this.issuePointsForm.amount : 0
      if(this.business.status_reward_range){
        let data_find = this.business.array_reward_range.find((e) => parseFloat(e.min) <= amount && parseFloat(e.max) >= amount)
        if(data_find){
          return this.formatNumber(parseFloat(data_find.point))
        }
        else{
          return 0
        }
      }
      else{
        let points = this.business.points_per_currency * amount
        if (parseInt(points) < parseInt(this.business.min_points_per_purchase)) points = this.business.min_points_per_purchase
        if (parseInt(points) > parseInt(this.business.max_points_per_purchase)) points = this.business.max_points_per_purchase
        return this.formatNumber(points)
      }
      
    }
  }
}
</script>
<style>
.font-red{
  color:#F44336;
}
.font-green{
  color:#4caf50;
}
.font-blue{
  color:#2196F3;
}
</style>
